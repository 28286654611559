import React, { useEffect } from 'react';
import { Timer as TimerIcon } from 'lucide-react';

interface TimerProps {
  timeLeft: number;
  onTimeUp: () => void;
  mode?: string;
}

export function Timer({ timeLeft, onTimeUp, mode }: TimerProps) {
  const getTimerColor = (time: number) => {
    if (time <= 3) return 'text-red-600';
    if (time <= 5) return 'text-red-500';
    if (time <= 10) return 'text-yellow-500';
    return 'text-gray-700';
  };

  const isSpeedMode = mode === 'speed';
  const pulseRate = timeLeft <= 3 ? 'animate-[pulse_0.5s_ease-in-out_infinite]' : '';

  useEffect(() => {
    if (timeLeft === 0) {
      onTimeUp();
    }
  }, [timeLeft, onTimeUp]);

  const formattedTime = isSpeedMode
    ? timeLeft.toFixed(1)
    : Math.ceil(timeLeft).toString();

  return (
    <div className={`flex items-center gap-2 text-2xl font-bold px-4 py-2 rounded-lg transition-colors ${
      getTimerColor(timeLeft)} ${pulseRate} ${
      timeLeft <= (isSpeedMode ? 1.5 : 10) ? 'bg-red-50' : ''
    }`}>
      <TimerIcon className="w-6 h-6" />
      <span className="font-['Architects_Daughter']">{formattedTime}s</span>
    </div>
  );
}
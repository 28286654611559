import React from 'react';
import { Trophy, Zap } from 'lucide-react';
import type { GameStats } from '../types';

interface ScoreBoardProps {
  stats: GameStats;
  highScore: number;
}

export function ScoreBoard({ stats, highScore }: ScoreBoardProps) {
  const getMultiplierColor = (multiplier: number) => {
    if (multiplier >= 3) return 'text-red-500';
    if (multiplier >= 2) return 'text-orange-500';
    if (multiplier >= 1.5) return 'text-yellow-500';
    return 'text-purple-500';
  };

  return (
    <div className="flex items-center justify-between text-2xl font-['Architects_Daughter'] bg-gray-50 rounded-lg p-4">
      <div className="flex items-center gap-2 group">
        <Trophy className="w-6 h-6 text-yellow-500" />
        <span className="transition-transform group-has-[.score-changed]:animate-score-bump">
          {stats.score}
        </span>
        <span className={`ml-2 text-sm ${getMultiplierColor(stats.multiplier)}`}>
          (x{stats.multiplier.toFixed(1)})
        </span>
      </div>
      <div className="text-gray-600 flex items-center gap-2">
        <Trophy className="w-5 h-5 text-gray-400" />
        High: {highScore}
      </div>
    </div>
  );
}
import React, { useEffect, useRef } from 'react';
import type { Problem as ProblemType } from '../types';
import { CheckCircle2, XCircle, Volume2, VolumeX } from 'lucide-react';
import useSound from 'use-sound';
import { sanitizeNumericInput, calculatePoints } from '../utils/validation';

interface ProblemProps {
  problem: ProblemType;
  onSubmit: (answer: number) => void;
  isPlaying: boolean;
  feedback: { isCorrect: boolean | null; show: boolean; points?: number } | null;
  showLevelUp: boolean;
}

export function Problem({ problem, onSubmit, isPlaying, feedback, showLevelUp }: ProblemProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [playSoundCorrect] = useSound('/sounds/pencil.mp3', { volume: 0.5 });
  const [playSoundWrong] = useSound('/sounds/eraser.mp3', { volume: 0.5 });
  const [playSoundLevelUp] = useSound('/sounds/bell.mp3', { volume: 0.5 });

  // Play sounds based on feedback
  useEffect(() => {
    if (feedback?.show) {
      if (feedback.isCorrect) {
        playSoundCorrect();
      } else {
        playSoundWrong();
      }
    }
  }, [feedback, playSoundCorrect, playSoundWrong]);

  // Play level up sound
  useEffect(() => {
    if (showLevelUp) {
      playSoundLevelUp();
    }
  }, [showLevelUp, playSoundLevelUp]);
  
  useEffect(() => {
    if (isPlaying) {
      inputRef.current?.focus();
    }
  }, [problem, isPlaying]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const rawInput = inputRef.current?.value || '';
    const answer = sanitizeNumericInput(rawInput, import.meta.env.DEV);

    if (answer !== null) {
      onSubmit(answer);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    } else {
      // Handle invalid input
      console.warn('Invalid input:', rawInput);
      if (inputRef.current) {
        inputRef.current.classList.add('animate-input-shake');
        setTimeout(() => {
          inputRef.current?.classList.remove('animate-input-shake');
        }, 500);
      }
    }
  };

  return (
    <div className="w-full max-w-md relative">
      {/* Level Up Animation */}
      {showLevelUp && (
        <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-full">
          <div className="bg-yellow-400 text-white px-4 py-2 rounded-full animate-level-up font-['Architects_Daughter'] text-xl">
            Level Up!
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="flex flex-col items-center gap-4">
        <div className="text-6xl font-['Architects_Daughter'] flex items-center gap-8 animate-problem-appear">
          <span>{problem.num1}</span>
          <span>+</span>
          <span>{problem.num2}</span>
          {problem.num3 !== undefined && (
            <>
              <span>+</span>
              <span>{problem.num3}</span>
            </>
          )}
          <span>=</span>
        </div>
        <input
          ref={inputRef}
          type="number"
          className="w-32 h-16 text-4xl text-center border-b-2 border-blue-400 focus:border-blue-600 outline-none bg-transparent font-['Architects_Daughter'] transition-all"
          disabled={!isPlaying}
          autoFocus
        />
        <button
          type="submit"
          className="px-6 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!isPlaying}
        >
          Submit
        </button>
      </form>
    </div>
  );
}